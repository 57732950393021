var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"card-header-title"},[_vm._v(" Changelog ")]),_c('div',{staticClass:"card-header-icon"},[_c('a',{staticClass:"icon",on:{"click":() => { this.$emit('close') }}},[_c('i',{staticClass:"material-icons"},[_vm._v("close")])])])]),_c('div',{staticClass:"card-content content"},[_c('h2',{staticClass:"has-text-primary subtitle"},[_vm._v(_vm._s(_vm.version))]),_vm._m(0),_vm._m(1),_vm._m(2),_c('p',[_vm._v("Thank you Rak for the feature request!")]),_vm._m(3),_vm._m(4),_vm._m(5),_c('p',[_vm._v("Fixed an issue where the mobile version of the Loot Solver would show incorrect numbers for some fields.")]),_vm._m(6),_c('p',[_vm._v("Improved the Viper and Pictomancer icons finally!")]),_c('p',[_vm._v("Fixed some issues in the OpenAPI schema!")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"divider"},[_c('i',{staticClass:"material-icons icon"},[_vm._v("expand_more")]),_vm._v(" FFXIV 7.1 Update "),_c('i',{staticClass:"material-icons icon"},[_vm._v("expand_more")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',[_vm._v(" Added the following new types of Gear released in 7.1; "),_c('ul',[_c('li',[_vm._v("Augmented Archeo Kingdom Weapons, Armour, and Accessories - Item Level 720")]),_c('li',[_vm._v("Ark Angel's Armour - Item Level 720")]),_c('li',[_vm._v("New EX Trial Weapons - Item Level 725")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"divider"},[_c('i',{staticClass:"material-icons icon"},[_vm._v("expand_more")]),_vm._v(" Quick-Set to BIS "),_c('i',{staticClass:"material-icons icon"},[_vm._v("expand_more")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',[_vm._v(" Now, whenever the Current Gear for a slot does not match the slot's BIS Gear, a button will appear to the right of the dropdown to allow you to instantly set the Current value to the BIS value. "),_c('ul',[_c('li',[_vm._v("This is in the hopes of making life a little easier for those times where you buy your BIS item, for example!")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"divider"},[_c('i',{staticClass:"material-icons icon"},[_vm._v("expand_more")]),_vm._v(" Bugfixes "),_c('i',{staticClass:"material-icons icon"},[_vm._v("expand_more")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',[_vm._v(" Fixed a timezone-based bug when creating manual Loot Entries. "),_c('ul',[_c('li',[_vm._v("Due to the server running in UTC time, people in timezones ahead of UTC can have issues where the server will tell them that they cannot create Loot for in the future.")]),_c('li',[_vm._v("To make it work, now the server will accept Loot for the day after its current day!")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"divider"},[_c('i',{staticClass:"material-icons icon"},[_vm._v("expand_more")]),_vm._v(" Minor Updates "),_c('i',{staticClass:"material-icons icon"},[_vm._v("expand_more")])])
}]

export { render, staticRenderFns }